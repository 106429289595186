<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>资料管理</el-breadcrumb-item>
      <el-breadcrumb-item>修改密码</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form class="form" :model="form" label-position="left" label-width="150px">
      <el-form-item label="旧密码">
        <el-input type="password" v-model="form.oldPassword" placeholder="请输入旧密码"></el-input>
      </el-form-item>
      <el-form-item label="新密码">
        <el-input type="password" v-model="form.newPassword1" placeholder="请输入新密码"></el-input>
      </el-form-item>
      <el-form-item label="重复新密码">
        <el-input type="password" v-model="form.newPassword2" placeholder="请再次输入新密码"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmitClick()">提交</el-button>
        <el-button @click="onCancelClick()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import api from "@/apis/api";

export default {
  name: "ChangePassword",
  data: function () {
    return {
      form: {
        oldPassword: "",
        newPassword1: "",
        newPassword2: ""
      }
    }
  },
  mounted: function () {

  },
  methods: {
    changePassword: function () {
      var _this = this;
      var data = {
        oldPassword: _this.form.oldPassword,
        newPassword: _this.form.newPassword1
      };
      api.changePassword(data).then(function (response) {
        _this.$message({
          message: "修改成功",
          type: "success"
        });
        _this.$router.push("/distribute/distribute");
      });
    },
    onSubmitClick: function () {
      if (!this.form.oldPassword || !this.form.newPassword1 || !this.form.newPassword2) {
        this.$message({
          message: "请输入完整信息",
          type: "error"
        });
      } else if (this.form.newPassword1 != this.form.newPassword2) {
        this.$message({
          message: "两次密码输入不一致",
          type: "error"
        });
      } else {
        this.changePassword();
      }
    },
    onCancelClick: function () {
      var _this = this;
      _this.$confirm("请确定是否放弃提交", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this.$router.push("/distribute/distribute");
      });
    }
  }
}
</script>

<style scoped>
.page {
  padding: 20px;
  border: 1px solid #EEEEEE;
  border-radius: 10px;
  box-shadow: #EEEEEE 0 0 10px;
}

.form {
  margin-top: 20px;
}
</style>
