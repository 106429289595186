<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>资料管理</el-breadcrumb-item>
      <el-breadcrumb-item>我的资料</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form class="form" :model="form" label-position="left" label-width="150px">
      <el-form-item label="ID">
        <span>{{ distributeId }}</span>
      </el-form-item>
      <el-form-item label="用户名">
        <span>{{ form.username }}</span>
      </el-form-item>
      <el-form-item label="密码">
        <span>******</span>
        <el-button type="text" size="small" @click="onChangePasswordClick()">修改密码</el-button>
      </el-form-item>
      <el-form-item label="名称">
        <span>{{ form.nickName }}</span>
      </el-form-item>
      <el-form-item label="平台名称">
        <span>{{ form.platformName }}</span>
      </el-form-item>
      <el-form-item label="分成比例">
        <span>{{ form.distributeRatio }}%</span>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import api from "@/apis/api";

export default {
  name: "AddDistribute",
  data: function () {
    return {
      distributeId: "",
      form: {
        username: "",
        nickName: "",
        platformName: "",
        distributeRatio: ""
      }
    }
  },
  mounted: function () {
    this.getDistribute();
  },
  methods: {
    getDistribute: function () {
      var _this = this;
      var data = {
        distributeId: _this.distributeId
      };
      api.getDistribute(data).then(function (response) {
        _this.distributeId = response.responseData.id;
        _this.form.username = response.responseData.username;
        _this.form.nickName = response.responseData.nickName;
        _this.form.platformName = response.responseData.platformName;
        _this.form.distributeRatio = response.responseData.distributeRatio;
      });
    },
    onChangePasswordClick:function (){
      this.$router.push("/distribute/changePassword");
    }
  }
}
</script>

<style scoped>
.page {
  padding: 20px;
  border: 1px solid #EEEEEE;
  border-radius: 10px;
  box-shadow: #EEEEEE 0 0 10px;
}

.form {
  margin-top: 20px;
}
</style>
