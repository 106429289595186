import { render, staticRenderFns } from "./TradeList.vue?vue&type=template&id=eccf7558&scoped=true&"
import script from "./TradeList.vue?vue&type=script&lang=js&"
export * from "./TradeList.vue?vue&type=script&lang=js&"
import style0 from "./TradeList.vue?vue&type=style&index=0&id=eccf7558&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eccf7558",
  null
  
)

export default component.exports