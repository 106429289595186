<template>
  <div class="page">
    <el-container>
      <el-header class="header">
        <div class="header-banner" @click="onBannerClick">
          <img class="header-banner-logo" src="@/assets/logo.png">
          <div class="header-banner-title">宠摆渡开放平台</div>
        </div>
        <div class="header-right">
          <el-dropdown class="header-user" @command="onDropdownCommand">
            <div class="header-user-username" @click="onUserClick">
              {{ username }} <i class="el-icon-arrow-down"></i>
            </div>
            <el-dropdown-menu>
              <el-dropdown-item command="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="header-cancel" @click="onCancelClick">返回首页</div>
        </div>
      </el-header>
      <el-container class="container">
        <el-aside>
          <el-menu :router="true" :default-active="$route.path">
            <el-submenu index="/trade">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span>订单管理</span>
              </template>
              <el-menu-item index="/trade/tradeList">订单列表</el-menu-item>
            </el-submenu>
            <el-submenu index="/distribute">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span>资料管理</span>
              </template>
              <el-menu-item index="/distribute/distribute">我的资料</el-menu-item>
            </el-submenu>
            <el-submenu index="/dictionary">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span>推广管理</span>
              </template>
              <el-menu-item index="/dictionary/dictionary">推广配置</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>
        <el-main>
          <router-view @login="onLogin"/>
        </el-main>
      </el-container>
      <el-footer class="footer">
        <div class="copyright">Copyright © 2023-2024 宠好运（深圳）科技有限公司. All Rights Reserved.</div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      username: ""
    }
  },
  mounted: function () {
    this.username = localStorage.getItem("username") ? localStorage.getItem("username") : "";
  },
  methods: {
    onBannerClick: function () {
      this.$router.push("/");
    },
    onCancelClick: function () {
      this.$router.push("/login");
    },
    onUserClick: function () {

    },
    onDropdownCommand: function (command) {
      if (command == "logout") {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        this.username = "";
        this.$router.push("/login");
      }
    },
    onLogin: function () {
      this.username = localStorage.getItem("username") ? localStorage.getItem("username") : "";
    }
  }
}
</script>

<style scoped>
.el-menu {
  border-right: none;
}

.header {
  position: fixed;
  display: flex;
  margin-bottom: 15px;
  padding: 0;
  color: #888888;
  border-bottom: 1px solid #EEEEEE;
  box-shadow: #EEEEEE 0 0 10px;
  background-color: #FFFFFF;
  width: 100%;
  z-index: 1000;
}

.header-banner {
  display: flex;
  padding: 15px;
  cursor: pointer;
}

.header-banner-logo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.header-banner-title {
  font-size: 20px;
  line-height: 30px;
  padding: 0 10px;
}

.header-right {
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  right: 0;
}

.header-cancel {
  padding: 15px;
  cursor: pointer;
  font-size: 16px;
  line-height: 30px;
}

.header-user {
  padding: 15px;
  cursor: pointer;
}

.header-user-username {
  font-size: 16px;
  line-height: 30px;
}

.container {
  margin: 60px 0;
}

.footer {
  position: fixed;
  padding: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: #FFFFFF;
  border-top: 1px solid #EEEEEE;
  box-shadow: #EEEEEE 0 0 10px;
  z-index: 1000;
}

.copyright {
  padding: 15px;
  font-size: 16px;
  line-height: 30px;
  color: #888888;
  text-align: right;
}

</style>
