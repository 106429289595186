import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import TradeList from "../views/trade/TradeList.vue";
import Distribute from "../views/distribute/Distribute.vue";
import ChangePassword from "../views/distribute/ChangePassword.vue";
import Dictionary from "../views/dictionary/Dictionary.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: Home
    },
    {
        path: "/login",
        component: Login
    },
    {
        path: "/trade/tradeList",
        component: TradeList
    },
    {
        path: "/distribute/distribute",
        component: Distribute
    },
    {
        path: "/distribute/changePassword",
        component: ChangePassword
    },
    {
        path: "/dictionary/dictionary",
        component: Dictionary
    }
];

const router = new VueRouter({
    routes
});

export default router;
