<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form class="form" :inline="true" :model="form">

    </el-form>
    <el-table class="table" :data="tradeList">
      <el-table-column
          prop="id"
          label="ID">
      </el-table-column>
      <el-table-column
          prop="tradeTime"
          :formatter="tradeTimeFormatter"
          label="下单时间">
      </el-table-column>
      <el-table-column
          prop="tradeNo"
          label="订单编号">
      </el-table-column>
      <el-table-column
          prop="tradePrice"
          :formatter="tradePriceFormatter"
          label="订单价格">
      </el-table-column>
      <el-table-column
          prop="tradeStatus"
          :formatter="tradeStatusFormatter"
          label="订单状态">
      </el-table-column>
      <el-table-column
          prop="distributePrice"
          :formatter="distributePriceFormatter"
          label="佣金价格">
      </el-table-column>
    </el-table>
    <el-pagination class="pagination"
                   @size-change="onSizeChange"
                   @current-change="onCurrentChange"
                   :current-page="currentPage"
                   :page-sizes="[10, 20, 50, 100]"
                   :page-size="pageSize"
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="total">
    </el-pagination>
  </div>
</template>

<script>
import api from "@/apis/api";

export default {
  name: "TradeList",
  data: function () {
    return {
      tradeList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      form: {}
    }
  },
  mounted: function () {
    this.getTradeList();
  },
  methods: {
    tradeTimeFormatter: function (row, column, cellValue, index) {
      if (row.createTime) {
        return (new Date(row.createTime * 1000)).format("yyyy-MM-dd hh:mm:ss");
      } else {
        return "";
      }
    },
    tradeStatusFormatter: function (row, column, cellValue, index) {
      if (row.tradeStatus == "-1") {
        return "已退款";
      } else if (row.tradeStatus == "1") {
        return "已支付";
      } else if (row.tradeStatus == "2") {
        return "已支付";
      } else if (row.tradeStatus == "3") {
        return "已支付";
      } else {
        return "-";
      }
    },
    tradePriceFormatter: function (row, column, cellValue, index) {
      return (parseInt(row.tradePrice)).toFixed(2) + "元";
    },
    distributePriceFormatter: function (row, column, cellValue, index) {
      return (parseInt(row.distributePrice)).toFixed(2) + "元";
    },
    onSizeChange: function (value) {
      this.pageSize = value;
      this.getTradeList();
    },
    onCurrentChange: function (value) {
      this.currentPage = value;
      this.getTradeList();
    },
    getTradeList: function () {
      var _this = this;
      var data = {
        pageCount: _this.currentPage,
        pageSize: _this.pageSize
      };
      api.getTradeList(data).then(function (response) {
        _this.tradeList = response.responseData.tradeList;
        _this.total = parseInt(response.responseData.totalCount);
      });
    }
  }
}
</script>

<style scoped>
.page {
  padding: 20px;
  border: 1px solid #EEEEEE;
  border-radius: 10px;
  box-shadow: #EEEEEE 0 0 10px;
}

.form {
  margin-top: 20px;
}

.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>
