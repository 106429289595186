<template>
  <div class="page">
    <div class="header">
      <div class="header-container">
        <img class="header-container-logo"
             src="https://liaoming-1257130299.cos.ap-guangzhou.myqcloud.com/20240716190906778e8173d10b3baa49b6dc21ba154ad2.png"
             alt="">
        <div class="header-container-title">宠摆渡开放平台</div>
        <div class="header-container-button" @click="onLoginClick" v-show="!loginStatus">登录</div>
        <div class="header-container-button" @click="onConsoleClick" v-show="loginStatus">控制台</div>
      </div>
    </div>
    <div class="main">
      <div class="main-container">
        <img class="main-container-background"
             src="https://liaoming-1257130299.cos.ap-guangzhou.myqcloud.com/20240716185209f60fbf192285846930b717b2902f0261.png"
             alt="">
        <div class="main-container-header">
          <div class="main-container-header-title">一站式宠物善后预约平台</div>
          <div class="main-container-header-text">汇聚全国宠物殡葬服务资源，为宠物行业合作伙伴提供一站式接入与变现支持。</div>
          <div class="main-container-header-button" @click="onExperienceClick">立即体验</div>
        </div>
        <div class="main-container-introduction">
          <div>
            <img class="main-container-introduction-icon"
                 src="https://liaoming-1257130299.cos.ap-guangzhou.myqcloud.com/202407161954358b1c69c19ffaa3fc0dce456a5cbca7a1.png"
                 alt="">
            <div class="main-container-introduction-title">引领宠物殡葬行业新风尚</div>
            <div class="main-container-introduction-text">
              <span>宠摆渡，作为国内宠物殡葬领域的头部品牌，我们专注于为宠物行业提供全面、专业的B端服务解决方案。平台汇聚了全国超过1000家优质的宠物殡葬店铺，致力于打造一个高效、透明、情感共鸣的宠物殡葬服务生态。我们深知宠物在主人心中的不可替代地位，因此，宠摆渡以尊重生命、传递温情为核心价值观，为每一位失去爱宠的宠物主提供贴心、专业的殡葬服务。</span>
            </div>
          </div>
          <img class="main-container-introduction-image"
               src="https://liaoming-1257130299.cos.ap-guangzhou.myqcloud.com/20240716194844852f465c6017d119d7b56d5bfe1685f6.png"
               alt="">
        </div>
        <div class="main-container-introduction">
          <div>
            <img class="main-container-introduction-icon"
                 src="https://liaoming-1257130299.cos.ap-guangzhou.myqcloud.com/20240716195446ca37982673f9913f5d430aaea55aaa68.png"
                 alt="">
            <div class="main-container-introduction-title">核心服务板块：一站式宠物殡葬解决方案</div>
            <div class="main-container-introduction-text">
              <span>1.专业服务整合：宠摆渡平台严格筛选并整合全国范围内的宠物殡葬店铺，确保每一家入驻店铺都具备专业的服务能力和良好的口碑。我们提供从遗体接送、清洁整理、个性化告别仪式到骨灰安置等一站式服务，让宠物主在悲痛中感受到温暖与安慰。<br><br>2.技术创新驱动：利用先进的互联网技术，宠摆渡开放平台实现了服务流程的线上化、智能化。宠物主可以通过平台轻松预约服务、查看服务进度、评价服务质量，同时，平台也为入驻商家提供了便捷的管理工具和数据分析服务，助力其提升服务效率和客户满意度。<br><br>3.情感关怀支持：宠摆渡深知宠物殡葬服务的特殊性，因此，我们特别注重情感关怀和支持。平台设有专业的心理咨询服务，为需要帮助的宠物主提供心理疏导和安慰。同时，我们还鼓励商家提供个性化的纪念服务，如定制纪念品、在线追思等，让宠物主能够以独特的方式缅怀逝去的爱宠。</span>
            </div>
          </div>
          <img class="main-container-introduction-image"
               src="https://liaoming-1257130299.cos.ap-guangzhou.myqcloud.com/202407161948566f86919626e12de74276f92de83f1f57.png"
               alt="">
        </div>
        <div class="main-container-introduction">
          <div>
            <img class="main-container-introduction-icon"
                 src="https://liaoming-1257130299.cos.ap-guangzhou.myqcloud.com/202407161954584795662727d79594ff6f3fc3b496f7fe.png"
                 alt="">
            <div class="main-container-introduction-title">开放合作，共赢未来</div>
            <div class="main-container-introduction-text">
              <span>宠摆渡开放平台诚邀全国宠物行业的同仁加入我们的大家庭，共同推动宠物殡葬行业的健康发展。我们为合作伙伴提供多种合作模式和灵活的接入方式，包括但不限于小程序、公众号等渠道的接入。通过我们的平台，合作伙伴可以轻松实现流量变现，根据成交订单获得丰厚的佣金回报。同时，我们也将积极分享平台资源、技术支持和市场推广经验，助力合作伙伴快速成长。</span>
            </div>
          </div>
          <img class="main-container-introduction-image"
               src="https://liaoming-1257130299.cos.ap-guangzhou.myqcloud.com/20240716194909f995589dbe3149b4f2ee1fa2175a5bd0.png"
               alt="">
        </div>
        <div class="main-container-introduction">
          <div>
            <img class="main-container-introduction-icon"
                 src="https://liaoming-1257130299.cos.ap-guangzhou.myqcloud.com/20211030232729b117e86e58e4fcbf614050077227a0dd.png"
                 alt="">
            <div class="main-container-introduction-title">携手并进，共创辉煌</div>
            <div class="main-container-introduction-text">
              <span>我们相信宠物殡葬行业将迎来更加广阔的发展前景。我们期待与每一位合作伙伴携手并进，共同探索宠物殡葬服务的新模式、新机遇。通过我们的共同努力，让每一位宠物主都能为爱宠找到最合适的归宿，让宠物殡葬成为传递温情、尊重生命的重要仪式。让我们共同书写宠物殡葬行业的新篇章，共创辉煌未来！</span>
            </div>
          </div>
          <img class="main-container-introduction-image"
               src="https://liaoming-1257130299.cos.ap-guangzhou.myqcloud.com/20240716194921258e4ce4b2555f4a0ee6942a1bdd9353.png"
               alt="">
        </div>
        <div class="main-container-start">
          <div class="main-container-start-title">开始接入你的服务</div>
          <div class="main-container-start-button" @click="onServiceClick">立即接入</div>
        </div>
        <div class="main-container-footer">Copyright © 2023-2024 宠好运（深圳）科技有限公司. All Rights Reserved.</div>
      </div>
    </div>
    <div class="dialog" @click="onExperienceCloseClick" v-show="experienceDialogShow">
      <div class="dialog-container" @click.stop="">
        <img class="dialog-container-qrcode"
             src="https://liaoming-1257130299.cos.ap-guangzhou.myqcloud.com/202407161911077172de474fa71ca4ee82a40e6e9c0e72.jpg"
             alt="">
      </div>
    </div>
    <div class="dialog" @click="onServiceCloseClick" v-show="serviceDialogShow">
      <div class="dialog-container" @click.stop="">
        <img class="dialog-container-qrcode"
             src="https://liaoming-1257130299.cos.ap-guangzhou.myqcloud.com/202407161913089cd7f2400d475c6b0ed26349ba89a3b4.png"
             alt="">
      </div>
    </div>
    <div class="dialog" @click="onLoginCloseClick" v-show="loginDialogShow">
      <div class="dialog-container" @click.stop="">
        <div class="dialog-container-login">
          <div style="height: 40px"></div>
          <input class="dialog-container-login-input" v-model="form.username" placeholder="输入用户名">
          <input class="dialog-container-login-input" v-model="form.password" type="password" placeholder="输入密码">
          <div class="dialog-container-login-button" @click="onLoginButtonClick">登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/apis/api";

export default {
  name: "Login",
  data: function () {
    return {
      form: {
        username: "",
        password: ""
      },
      loginDialogShow: false,
      experienceDialogShow: false,
      serviceDialogShow: false,
      loginStatus: false
    }
  },
  methods: {
    login: function () {
      var _this = this;
      var data = {
        username: _this.form.username,
        password: _this.form.password,
      };
      api.login(data).then(function (response) {
        localStorage.setItem("token", response.responseData.token);
        localStorage.setItem("username", _this.form.username);
        _this.$emit("login");
        _this.$message({
          message: "登录成功",
          type: "success"
        });
        _this.loginStatus = true;
        _this.loginDialogShow = false;
      });
    },
    onLoginButtonClick: function () {
      if (this.form.username && this.form.password) {
        this.login();
      } else {
        this.$message({
          message: "请输入完整信息",
          type: "error"
        });
      }
    },
    onLoginClick: function () {
      this.loginDialogShow = true;
    },
    onLoginCloseClick: function () {
      this.loginDialogShow = false;
    },
    onExperienceClick: function () {
      this.experienceDialogShow = true;
    },
    onExperienceCloseClick: function () {
      this.experienceDialogShow = false;
    },
    onServiceClick: function () {
      this.serviceDialogShow = true;
    },
    onServiceCloseClick: function () {
      this.serviceDialogShow = false;
    },
    onConsoleClick: function () {
      this.$router.push("/");
    }
  }
}
</script>

<style scoped>
.page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  background-color: #FAFAFA;
  min-width: 1228px;
  overflow-x: scroll;
}

.header {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 64px;
  background-color: #FFFFFF;
}

.header-container {
  display: flex;
  width: 1228px;
  height: 48px;
  padding: 8px;
}

.header-container-logo {
  width: 140px;
  height: 48px;
}

.header-container-title {
  width: 1024px;
  height: 48px;
  font-size: 20px;
  line-height: 48px;
  font-weight: 500;
}

.header-container-button {
  width: 72px;
  height: 48px;
  font-size: 16px;
  line-height: 48px;
  font-weight: 400;
  text-align: right;
  cursor: pointer;
}

.main {
  display: flex;
  justify-content: center;
  width: 100%;
}

.main-container {
  width: 1228px;
}

.main-container-background {
  position: absolute;
  z-index: -1;
  top: 90px;
  left: 1080px;
  width: 720px;
  height: 720px;
}

.main-container-header {
  width: 1228px;
  padding: 260px 0;
}

.main-container-header-title {
  width: 1228px;
  height: 80px;
  font-size: 56px;
  line-height: 56px;
  font-weight: 600;
}

.main-container-header-text {
  width: 1228px;
  height: 60px;
  font-size: 24px;
  line-height: 60px;
  font-weight: 400;
  color: #888888;
}

.main-container-header-button {
  width: 180px;
  height: 60px;
  margin-top: 60px;
  font-size: 24px;
  line-height: 60px;
  text-align: center;
  border-radius: 30px;
  color: #FFFFFF;
  background-color: #07C160;
  cursor: pointer;
}

.main-container-introduction {
  display: flex;
  width: 1128px;
  margin-top: 40px;
  padding: 60px 48px;
  border-radius: 24px;
  background-color: #FFFFFF;
}

.main-container-introduction-icon {
  width: 56px;
  height: 56px;
}

.main-container-introduction-title {
  width: 600px;
  margin-top: 24px;
  font-size: 28px;
  line-height: 28px;
  font-weight: 600;
}

.main-container-introduction-text {
  width: 600px;
  margin-top: 24px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  color: #888888;
}

.main-container-introduction-image {
  width: 480px;
  height: 320px;
  margin-left: 40px;
}

.main-container-start {
  width: 1224px;
  height: 156px;
  margin-top: 160px;
  padding-top: 92px;
  padding-bottom: 48px;
  border-radius: 20px;
  background-image: url("https://liaoming-1257130299.cos.ap-guangzhou.myqcloud.com/20240716200303d230c4a6a1a2a1eb99085700880fe065.png");
}

.main-container-start-title {
  width: 1224px;
  height: 48px;
  font-size: 32px;
  line-height: 48px;
  font-weight: 600;
  text-align: center;
  color: #FFFFFF;
}

.main-container-start-button {
  width: 120px;
  height: 40px;
  margin-top: 40px;
  margin-left: 552px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  border-radius: 20px;
  background-color: #FFFFFF;
  cursor: pointer;
}

.main-container-footer {
  width: 1224px;
  height: 21px;
  margin-top: 160px;
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  text-align: center;
  color: #888888;
}


.dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-container-qrcode {
  width: 384px;
  height: 384px;
}

.dialog-container-login {
  width: 400px;
  height: 300px;
  border-radius: 20px;
  background-color: #FFFFFF;
}

.dialog-container-login-input {
  width: 280px;
  height: 40px;
  margin-left: 40px;
  margin-top: 20px;
  padding: 0 20px;
  background-color: #F7F7F7;
  border: none;
  border-radius: 20px;
}

.dialog-container-login-button {
  width: 320px;
  height: 40px;
  margin-top: 60px;
  margin-left: 40px;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  border-radius: 30px;
  color: #FFFFFF;
  background-color: #07C160;
  cursor: pointer;
}
</style>
