import Vue from "vue";
import router from "@/router";
import config from "@/configs/config";

var _this = Vue.prototype;

var xs = {};

var objectToQueryString = function (object) {
    var queryStringArray = [];
    for (var key in object) {
        if (object.hasOwnProperty(key)) {
            queryStringArray.push(encodeURIComponent(key) + "=" + encodeURIComponent(object[key]));
        }
    }
    return queryStringArray.join("&");
}

xs.request = function (object) {
    var url = object.url || "";
    var data = object.data || {};
    var header = object.header || {};
    var method = object.method || "GET";
    var success = object.success || null;
    var fail = object.fail || null;
    var complete = object.complete || null;

    var response = {};

    var xhr = new XMLHttpRequest();

    xhr.onerror = function () {
        response.errMsg = "request:fail";
        if (typeof fail === "function") {
            fail(response);
        }
        if (typeof complete === "function") {
            complete(response);
        }
    };

    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
            response.errMsg = "request:ok";
            response.statusCode = xhr.status;
            try {
                response.data = JSON.parse(xhr.response);
            } catch (e) {
                response.data = xhr.response;
            }
            if (typeof success === "function") {
                success(response);
            }
            if (typeof complete === "function") {
                complete(response);
            }
        }
    };

    if (method.toUpperCase() === "GET") {
        if (data) {
            xhr.open(method, url + "?" + objectToQueryString(data));
        } else {
            xhr.open(method, url);
        }
        for (var key in header) {
            if (header.hasOwnProperty(key)) {
                xhr.setRequestHeader(key, header[key]);
            }
        }
        xhr.send();
    } else if (method.toUpperCase() === "POST") {
        xhr.open(method, url);
        for (var key in header) {
            if (header.hasOwnProperty(key)) {
                xhr.setRequestHeader(key, header[key]);
            }
        }
        xhr.send(objectToQueryString(data));
    }
};

xs.uploadFile = function (object) {
    var url = object.url || "";
    var filePath = object.filePath || "";
    var name = object.name || "";
    var header = object.header || {};
    var success = object.success || null;
    var fail = object.fail || null;
    var complete = object.complete || null;

    var response = {};

    var xhr = new XMLHttpRequest();

    xhr.onerror = function () {
        response.errMsg = "uploadFile:fail";
        if (typeof fail === "function") {
            fail(response);
        }
        if (typeof complete === "function") {
            complete(response);
        }
    };

    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
            response.errMsg = "uploadFile:ok";
            response.statusCode = xhr.status;
            try {
                response.data = JSON.parse(xhr.response);
            } catch (e) {
                response.data = xhr.response;
            }
            if (typeof success === "function") {
                success(response);
            }
            if (typeof complete === "function") {
                complete(response);
            }
        }
    };

    xhr.open("POST", url);
    for (var key in header) {
        if (header.hasOwnProperty(key)) {
            xhr.setRequestHeader(key, header[key]);
        }
    }
    var formData = new FormData();
    formData.append(name, filePath);
    xhr.send(formData);
}

var request = function (url, method, data) {
    return new Promise(function (resolve, reject) {
        _this.$loading({});
        xs.request({
            url: config.requestBaseUrl + url,
            data: data,
            header: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Token": localStorage.getItem("token")
            },
            method: method,
            success: function (res) {
                _this.$loading({}).close();
                console.log("[xs.request:success]\n" + "url:" + config.requestBaseUrl + url + "\n" + "data:" + JSON.stringify(data) + "\n" + "method:" + method + "\n" + "res:" + JSON.stringify(res) + "\n");
                if (res.statusCode >= 200 && res.statusCode < 300 || res.statusCode === 304) {
                    if (res.data.responseCode == 0) {
                        resolve(res.data);
                    } else if (res.data.responseCode == 101) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("username");
                        _this.$message({
                            message: "获取登录信息失败，请重新登录！",
                            type: "error"
                        });
                        router.push("/login");
                        reject(res);
                    } else {
                        _this.$message({
                            message: "" + res.data.responseMessage,
                            type: "error"
                        });
                        reject(res);
                    }
                } else {
                    _this.$message({
                        message: "获取数据失败！" + res.errMsg,
                        type: "error"
                    });
                    reject(res);
                }
            },
            fail: function (res) {
                _this.$loading({}).close();
                console.log("[xs.request:fail]\n" + "url:" + config.requestBaseUrl + url + "\n" + "data:" + JSON.stringify(data) + "\n" + "method:" + method + "\n" + "res:" + JSON.stringify(res) + "\n");
                _this.$message({
                    message: "获取数据失败！" + res.errMsg,
                    type: "error"
                });
                reject(res);
            }
        });
    });
};

var uploadFile = function (url, name, filePath) {
    return new Promise(function (resolve, reject) {
        _this.$loading({});
        xs.uploadFile({
            url: config.uploadFileBaseUrl + url,
            filePath: filePath,
            name: name,
            header: {
                "Token": localStorage.getItem("token")
            },
            success: function (res) {
                _this.$loading({}).close();
                console.log("[wx.request:success]\n" + "url:" + config.uploadFileBaseUrl + url + "\n" + "filePath:" + filePath + "\n" + "name:" + name + "\n" + "res:" + JSON.stringify(res) + "\n");
                if (res.statusCode >= 200 && res.statusCode < 300 || res.statusCode === 304) {
                    if (res.data.responseCode == 0) {
                        resolve(res.data);
                    } else if (res.data.responseCode == 101) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("username");
                        _this.$message({
                            message: "获取登录信息失败，请重新登录！",
                            type: "error"
                        });
                        router.push("/login");
                        reject(res);
                    } else {
                        _this.$message({
                            message: "" + res.data.responseMessage,
                            type: "error"
                        });
                        reject(res);
                    }
                } else {
                    _this.$message({
                        message: "文件上传失败！" + res.errMsg,
                        type: "error"
                    });
                    reject(res);
                }
            },
            fail: function (res) {
                _this.$loading({}).close();
                console.log("[xs.request:fail]\n" + "url:" + config.requestBaseUrl + url + "\n" + "data:" + JSON.stringify(data) + "\n" + "method:" + method + "\n" + "res:" + JSON.stringify(res) + "\n");
                _this.$message({
                    message: "文件上传失败！" + res.errMsg,
                    type: "error"
                });
                reject(res);
            }
        });
    });
};


var api = {
    login: function (data) {
        var url = "distribute/distribute/login";
        var method = "POST";
        return request(url, method, data);
    },
    getTradeList: function (data) {
        var url = "distribute/trade/getTradeList";
        var method = "GET";
        return request(url, method, data);
    },
    getStatistic: function (data) {
        var url = "distribute/distribute/getStatistic";
        var method = "GET";
        return request(url, method, data);
    },
    getSettleOrderList: function (data) {
        var url = "distribute/distribute/getSettleOrderList";
        var method = "GET";
        return request(url, method, data);
    },
    getDistribute: function (data) {
        var url = "distribute/distribute/getDistribute";
        var method = "GET";
        return request(url, method, data);
    },
    changePassword: function (data) {
        var url = "distribute/distribute/changePassword";
        var method = "POST";
        return request(url, method, data);
    },
    generateQrCode: function (data) {
        var url = "distribute/distribute/generateQrCode";
        var method = "POST";
        return request(url, method, data);
    },

    uploadImage: function (filePath) {
        var url = "distribute/file/uploadImage";
        var name = "file";
        return uploadFile(url, name, filePath);
    }
};

export default api;
