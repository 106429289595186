<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>推广管理</el-breadcrumb-item>
      <el-breadcrumb-item>推广配置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="card-list">
      <div class="card-item">
        <div class="card-item-title">小程序跳转代码</div>
        <el-input class="textarea" type="textarea" v-model="miniProgramCode" :rows="5"></el-input>
        <el-button class="button" type="primary" size="small" @click="onMiniProgramCodeCopyClick()">复制代码</el-button>
      </div>
      <div class="card-item">
        <div class="card-item-title">小程序码</div>
        <el-image class="qrcode" :src="qrCode" fit="fill" alt="小程序码" :preview-src-list="[qrCode]"></el-image>
      </div>
      <div class="card-item">
        <div class="card-item-title">公众号跳转代码</div>
        <div style="margin-top: 30px;">绑定小程序</div>
        <div style="display: flex;">
          <el-input style="width: 240px;margin-top: 15px;" v-model="miniProgramAppId"></el-input>
          <el-button style="margin-top: 15px;margin-left: 15px;" type="primary" size="small"
                     @click="onMiniProgramAppIdCopyClick()">复制AppId
          </el-button>
        </div>
        <el-input class="textarea" type="textarea" v-model="officialAccountCode" :rows="5"></el-input>
        <el-button class="button" type="primary" size="small" @click="onOfficialAccountCodeCopyClick()">复制代码</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/apis/api";

export default {
  name: "Dictionary",
  data: function () {
    return {
      miniProgramCode: "wx.navigateToMiniProgram({\n" +
          "    appId: \"wxf014abcf694a8011\",\n" +
          "    path: \"pages/home/home?distributeId=你的ID\"\n" +
          "});",
      qrCode: "",
      miniProgramAppId: "wxf014abcf694a8011",
      officialAccountCode: "pages/home/home?distributeId=你的ID"
    }
  },
  mounted: function () {
    this.getDistribute();
    this.generateQrCode();
  },
  methods: {
    onMiniProgramCodeCopyClick: function () {
      var _this = this;
      _this.$copyText(_this.miniProgramCode).then(function (e) {
        _this.$message({
          type: "success",
          message: "复制成功"
        });
      }, function (e) {
        _this.$message({
          message: "复制失败！",
          type: "error"
        });
      });
    },
    onMiniProgramAppIdCopyClick: function () {
      var _this = this;
      _this.$copyText(_this.miniProgramAppId).then(function (e) {
        _this.$message({
          type: "success",
          message: "复制成功"
        });
      }, function (e) {
        _this.$message({
          message: "复制失败！",
          type: "error"
        });
      });
    },
    onOfficialAccountCodeCopyClick: function () {
      var _this = this;
      _this.$copyText(_this.miniProgramCode).then(function (e) {
        _this.$message({
          type: "success",
          message: "复制成功"
        });
      }, function (e) {
        _this.$message({
          message: "复制失败！",
          type: "error"
        });
      });
    },
    getDistribute: function () {
      var _this = this;
      var data = {
        distributeId: _this.distributeId
      };
      api.getDistribute(data).then(function (response) {
        var distributeId = response.responseData.id;
        _this.miniProgramCode =
            "wx.navigateToMiniProgram({\n" +
            "    appId: \"wxf014abcf694a8011\",\n" +
            "    path: \"pages/home/home?distributeId=" + distributeId + "\"\n" +
            "});";
        _this.officialAccountCode = "pages/home/home?distributeId=" + distributeId;
      });
    },
    generateQrCode: function () {
      var _this = this;
      var data = {};
      api.generateQrCode(data).then(function (response) {
        _this.qrCode = response.responseData.qrCode;
      });
    }
  }
}
</script>

<style scoped>
.page {
  padding: 20px;
  border: 1px solid #EEEEEE;
  border-radius: 10px;
  box-shadow: #EEEEEE 0 0 10px;
}

.card-list {
  display: flex;
}

.card-item {
  width: 360px;
  height: 480px;
  padding: 20px;
  margin-top: 10px;
  margin-left: 10px;
  border: 1px solid #EEEEEE;
  border-radius: 10px;
  box-shadow: #EEEEEE 0 0 10px;
}

.card-item-title {
  width: 360px;
  height: 30px;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
}

.textarea {
  margin-top: 30px;
}

.qrcode {
  width: 180px;
  height: 180px;
  margin-top: 30px;
  margin-left: 90px;
}

.button {
  margin-top: 30px;
  margin-left: 120px;
}
</style>
