<template>
  <div class="page">
    <div class="card-list">
      <div class="card-item">
        <div class="card-item-count">{{ totalDistributePrice }}</div>
        <div class="card-item-text">总佣金</div>
      </div>
      <div class="card-item">
        <div class="card-item-count">{{ currentDistributePrice }}</div>
        <div class="card-item-text">本月佣金</div>
      </div>
    </div>
    <el-table class="table" :data="settleOrderList">
      <el-table-column
          prop="id"
          label="ID">
      </el-table-column>
      <el-table-column
          prop="tradeTime"
          :formatter="createTimeFormatter"
          label="提现时间">
      </el-table-column>
      <el-table-column
          prop="settlePrice"
          :formatter="settlePriceFormatter"
          label="提现金额">
      </el-table-column>
      <el-table-column
          prop="status"
          :formatter="statusFormatter"
          label="提现状态">
      </el-table-column>
    </el-table>
    <el-pagination class="pagination"
                   @size-change="onSizeChange"
                   @current-change="onCurrentChange"
                   :current-page="currentPage"
                   :page-sizes="[10, 20, 50, 100]"
                   :page-size="pageSize"
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="total">
    </el-pagination>
  </div>
</template>

<script>
import api from "@/apis/api";

export default {
  name: "Home",
  data: function () {
    return {
      totalDistributePrice: "0.00元",
      currentDistributePrice: "0.00元",
      settleOrderList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0
    }
  },
  mounted: function () {
    this.getStatistic();
    this.getSettleOrderList();
  },
  methods: {
    createTimeFormatter: function (row, column, cellValue, index) {
      if (row.createTime) {
        return (new Date(row.createTime * 1000)).format("yyyy-MM-dd hh:mm:ss");
      } else {
        return "";
      }
    },
    settlePriceFormatter: function (row, column, cellValue, index) {
      return (parseInt(row.settlePrice)).toFixed(2) + "元";
    },
    statusFormatter: function (row, column, cellValue, index) {
      if (row.status == "0") {
        return "未完成";
      } else if (row.status == "1") {
        return "已完成";
      }
    },
    onSizeChange: function (value) {
      this.pageSize = value;
      this.getSettleOrderList();
    },
    onCurrentChange: function (value) {
      this.currentPage = value;
      this.getSettleOrderList();
    },
    getStatistic: function () {
      var _this = this;
      var data = {};
      api.getStatistic(data).then(function (response) {
        _this.totalDistributePrice = (parseInt(response.responseData.totalDistributePrice)).toFixed(2) + "元";
        _this.currentDistributePrice = (parseInt(response.responseData.currentDistributePrice)).toFixed(2) + "元";
      });
    },
    getSettleOrderList: function () {
      var _this = this;
      var data = {
        pageCount: _this.currentPage,
        pageSize: _this.pageSize
      };
      api.getSettleOrderList(data).then(function (response) {
        _this.settleOrderList = response.responseData.settleOrderList;
        _this.total = parseInt(response.responseData.totalCount);
      });
    }
  }
}
</script>

<style scoped>
.page {
  padding: 20px;
  border: 1px solid #EEEEEE;
  border-radius: 10px;
  box-shadow: #EEEEEE 0 0 10px;
}

.pagination {
  margin-top: 20px;
  text-align: right;
}

.card-list {
  display: flex;
}

.card-item {
  width: 240px;
  height: 100px;
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 10px;
  background-color: #07C160;
}

.card-item-count {
  width: 240px;
  height: 60px;
  font-size: 24px;
  line-height: 60px;
  text-align: center;
  color: #FFFFFF;
}

.card-item-text {
  width: 240px;
  height: 40px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
}
</style>
